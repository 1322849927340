<template>
<div>
  <strong>count: {{showRows.length}}</strong>
  <b-form-group class="my-3">
    records to include:
    <b-form-checkbox-group
      id="timeline-config"
      v-model="selected"
      :options="options"
      />
  </b-form-group>
  <b-form-group class="my-3">
    optional columns:
    <b-form-checkbox-group
      id="timeline-columns-config"
      v-model="fieldsSelected"
      :options="fieldsOptions"
      />
  </b-form-group>
  <b-table style="font-size: 12px; cursor: pointer;" striped hover :items="showRows" :fields="fields" @row-clicked="showInitiative"></b-table>
</div>
</template>

<script>
import _ from 'lodash'

const fieldsConfigCore = [
  { key: "date", sortable: true, label: "Date", class: "date-column" },
  { key: "type", sortable: true, label: "Type" },
  { key: "name", sortable: true, label: "Initiative" }
]

const fieldsConfigOptional = [
  { key: "country", sortable: true, label: "Country" },
  { key: "mandatory", sortable: true, label: "Mandatory" },
  { key: "optional", sortable: true, label: "Optional" },
  { key: "comply_explain", sortable: true, label: "Comply/explain" }
]

export default {
  name: 'InitiativesTimeline',
  props: ['rows'],
  components: {
  },
  computed: {
    fields: function () {
      const selector = this.fieldsSelected.map(x => { return { key: x } })
      const optional = _.intersectionBy(fieldsConfigOptional, selector, 'key')
      const all = fieldsConfigCore.concat(optional)
      return all
    },
    showRows: function () {
      const config = [
        { field: 'effective', label: 'effective' },
        { field: 'issued', label: 'issued' },
        { field: 'first_reporting', label: 'first reporting' }
      ]
      let result = []
      this.rows.forEach(x => {
        config.forEach(y => {
          if (x[y.field] && this.selected.find(z => z === y.label)) {
            const row = {
              date: x[y.field],
              type: y.label,
              ...x
            }
            result.push(row)
          }
        })
      })
      result = _.sortBy(result, ['date', 'type', 'name'])
      return result
    }
  },
  created: async function () {
  },
  data () {
    return {
      fieldsOptions: fieldsConfigOptional.map(x => { return { value: x.key, text: x.label } }),
      fieldsSelected: fieldsConfigOptional.map(x => x.key),
      options: ['first reporting', 'effective', 'issued'],
      selected: ['first reporting', 'effective', 'issued']
    }
  },
  methods: {
    showInitiative: function (initiative, index, event) {
      this.$router.push({ name: 'ImpactInitiative', params: { id: initiative.id } })
    }
  },
  watch: {
  }
}
</script>

<style>
.date-column {
  width: 100px;
}
</style>
