<template>
<div>
  <b-container fluid class="bg-white">
    <b-row class="mb-4">
      <b-col>
        <div v-if="countrySelected.length == 1">
          <h2>{{countrySelected[0]}}</h2>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <h3>{{rows.length}} initiatives</h3>
      </b-col>
    </b-row>
    <b-row class="mb-4" v-if="Array.isArray(barCharts) && barCharts.length > 0">
      <b-col>
        <world-map-simple v-if="Array.isArray(barCharts[0].data) && barCharts[0].data.length > 0" :config="barCharts[0]" />
      </b-col>
    </b-row>
    <b-row cols="2">
      <b-col class="mb-4" v-for="chart in barCharts" :key="chart.key">
        <bar-stacked-simple v-if="Array.isArray(chart.data) && chart.data.length > 0" :config="chart"/>
      </b-col>
    </b-row>
    <b-row class="my-5" v-if="rows.length > 0">
      <b-col>
        <div><strong>Timeline</strong></div>
        <timeline-simple :rows="rows" />
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import _ from 'lodash'
import * as echarts from 'echarts'
import theme from '@/libs/colorTheme'
import workflows from '@/workflows/initiative'

import BarStackedSimple from '@/components/charts/v1/barStackedSimple.vue'
import WorldMapSimple from '@/components/charts/v1/worldMapSimple.vue'
import TimelineSimple from '@/components/charts/v1/timelineSimple.vue'

echarts.registerTheme('kpmg', theme)

const dataGetters = {
  array: function (rows, property, name) {
    let data = []
    const all = []
    _.each(rows, row => {
      _.each(row[property], p => {
        all.push(p[name])
      })
    })
    _.each(_.countBy(all, x => x), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  object: function (rows, property, name) {
    let data = []
    const rowsModified = []
    _.each(rows, row => {
      if (row.assurance) {
        rowsModified.push(row)
      }
    })
    _.each(_.countBy(rowsModified, x => x[property][name]), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  obligation: function (rows, property, name) {
    let data = []
    const all = []
    _.each(rows, row => {
      if (row.mandatory) {
        all.push('mandatory')
      }
      if (row.comply_explain) {
        all.push('comply or explain')
      }
      if (row.optional) {
        all.push('optional')
      }
    })
    _.each(_.countBy(all, x => x), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  property: function (rows, property, name) {
    let data = []
    _.each(_.countBy(rows, x => x[property]), (value, key) => {
      data.push({ name: key, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  },
  workflow: function (rows, property, name) {
    let data = []
    _.each(_.countBy(rows, x => x[property]), (value, key) => {
      const w = workflows.find(y => y.id === parseInt(key))
      const name = w.name
      data.push({ name: name, value: value })
    })
    data = _.sortBy(data, ['value'])
    return data
  }
}

function getHeight (data) {
  return (Math.floor(data.length / 10) + 1) * 140 + 'px'
}

function getRandomKey () {
  return Math.floor(Math.random() * 10000)
}

export default {
  components: {
    BarStackedSimple,
    TimelineSimple,
    WorldMapSimple
  },
  props: ['rows'],
  computed: {
    barCharts: function () {
      const results = []
      const charts = [
        {
          dataGetter: 'property',
          property: 'country',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per country',
          headerRecord: 'countries'
        },
        {
          dataGetter: 'array',
          property: 'regulators',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per regulator',
          headerRecord: 'regulators'
        },
        {
          dataGetter: 'array',
          property: 'reportingframeworks',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per reporting framework',
          headerRecord: 'reporting frameworks'
        },
        {
          dataGetter: 'array',
          property: 'impacttopics',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per topic',
          headerRecord: 'topics'
        },
        {
          dataGetter: 'object',
          property: 'impactstatus',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per status',
          headerRecord: 'statuses'
        },
        {
          dataGetter: 'object',
          property: 'assurance',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per assurance type',
          headerRecord: 'types'
        },
        {
          dataGetter: 'workflow',
          property: 'workflow_id',
          nameProperty: 'name',
          nameSeries: 'Initiatives',
          header: 'Initiatives per workflow stage',
          headerRecord: 'stages'
        },
        {
          dataGetter: 'obligation',
          property: 'workflow_id',
          nameProperty: 'name',
          nameSeries: 'Obligation',
          header: 'Initiatives per obligation type',
          headerRecord: 'types'
        }
      ]
      _.each(charts, chart => {
        const data = dataGetters[chart.dataGetter](this.rows, chart.property, chart.nameProperty)
        const config = {
          data: data,
          name: chart.nameSeries,
          header: chart.header,
          headerRecord: chart.headerRecord,
          height: getHeight(data),
          width: '100%',
          key: getRandomKey()
        }
        results.push(config)
      })
      return results
    },
    countrySelected: {
      get () {
        return this.$store.state.countrySelected
      },
      set (payload) {
        this.$store.commit('setCountrySelected', payload)
      }
    },
    sunburst: function () {
      const d = [
        {
          name: 'mandatory',
          children: [],
          x_field: 'mandatory'
        },
        {
          name: 'optional',
          children: [],
          x_field: 'optional'
        },
        {
          name: 'comply or explain',
          children: [],
          x_field: 'comply_explain'
        }
      ]
      _.each(d, level1 => {
        const level1set = _.filter(this.rows, level1.x_field)
        let countries = []
        _.each(level1set, r => {
          countries.push(r.country)
        })
        countries = _.uniq(countries)
        _.each(countries, c => {
          level1.children.push({ name: c, children: [] })
        })
        _.each(level1.children, c => {
          const level2 = _.filter(level1set, row => {
            return row.country === c.name
          })
          const childrenL = []
          _.each(level2, rr => {
            childrenL.push({ name: rr.name, value: 1 })
          })
          c.children = childrenL
        })
      })
      return {
        data: d,
        height: '1000px',
        width: '1000px',
        header: 'Sample data'
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
    }
  },
  created: async function () {
    this.load()
  },
  methods: {
    load: function () {
      // console.log(this.rows)
    }
  }
}
</script>

<style>
</style>
