<template>
    <b-container class="card-body p-0">
      <b-row>
        <b-col>
          <router-link :to="{ name: 'ImpactInitiative', params: { id: item.id }}"><h4 class="card-title">{{item.name}}</h4></router-link>
          <div>{{item.country}} | {{item.impactstatus.name}}</div>
          <div>{{item.headline}}</div>
          <div>
            Topics:
            <span v-for="(topic, index) in item.impacttopics" :key="'topic-'+topic.id">
              <router-link :to="{ name: 'ImpactTopic', params: { id: topic.id }}">{{topic.name}}</router-link><a v-if="index + 1 < item.impacttopics.length"> | </a>
            </span>
          </div>
          <div>
            Experts:
            <span v-for="(expert, index) in item.impactexperts" :key="'expert-'+expert.id">
              <router-link :to="{ name: 'ImpactExpert', params: { id: expert.id }}">{{expert.forename}} {{expert.surname}}</router-link><a v-if="index + 1 < item.impactexperts.length"> | </a>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'ImpactInitiative',
  props: ['item', 'username']
}
</script>

<style>
</style>
