<template>
<div>
  <b-form-group>
    <b-form-checkbox-group
      id="timeline-config"
      v-model="selected"
      :options="options"
      />
  </b-form-group>
  <div id="timelineVisualization"></div>
</div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { DataSet } from "vis-data/peer"
import { Timeline } from "vis-timeline/peer"
import "vis-timeline/styles/vis-timeline-graph2d.css"

function delay (period) {
  return new Promise(resolve => {
    setTimeout(resolve, period)
  })
}

export default {
  components: {
  },
  props: ['rows'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.timelineShow()
  },
  data () {
    return {
      selected: ['first reporting', 'effective', 'issued'],
      options: ['first reporting', 'effective', 'issued'],
      timeline: null
    }
  },
  methods: {
    reload: function () {
      this.timeline.destroy()
      this.timeline = null
      this.timelineShow()
    },
    timelineShow: async function () {
      if (this.timeline) {
        this.timeline.destroy()
      }
      await delay(100)
      // DOM element where the Timeline will be attached
      const container = document.getElementById("timelineVisualization")

      // Create a DataSet (allows two way data-binding)
      const groupsData = []
      const dates = []
      const timelineData = []
      if (this.selected.includes('first reporting')) {
        groupsData.push('first reporting')
      }
      if (this.selected.includes('effective')) {
        groupsData.push('effective')
      }
      if (this.selected.includes('issued')) {
        groupsData.push('issued')
      }
      _.each(this.rows, x => {
        if (x.first_reporting && this.selected.includes('first reporting')) {
          const item = { id: x.id + 'fr', content: x.name, start: x.first_reporting, group: 'first reporting', className: 'timeline-first_reporting' }
          timelineData.push(item)
          dates.push(x.first_reporting)
        }
        if (x.effective && this.selected.includes('effective')) {
          const item = { id: x.id + 'e', content: x.name, start: x.effective, group: 'effective', className: 'timeline-effective' }
          timelineData.push(item)
          dates.push(x.effective)
        }
        if (x.issued && this.selected.includes('issued')) {
          const item = { id: x.id + 'i', content: x.name, start: x.issued, group: 'issued', className: 'timeline-issued' }
          timelineData.push(item)
          dates.push(x.issued)
        }
      })
      const groupsDataUniq = _.uniq(groupsData)
      const groupsDataObjects = _.map(groupsDataUniq, x => {
        return { id: x, content: x }
      })
      const items = new DataSet(timelineData)
      const groups = new DataSet(groupsDataObjects)
      // Configuration for the Timeline
      const options = {
        start: moment(_.min(dates)).subtract(1, 'year'),
        end: moment(_.max(dates)).add(1, 'year')
      }

      // Create a Timeline
      this.timeline = new Timeline(container, items, groups, options)
    }
  },
  watch: {
    'rows': 'reload',
    'selected': 'reload'
  }
}
</script>
<style>
/* Dear CEO */
.vis-item.timeline-first_reporting {
  background-color: #0c233c;
  color: white;
}

/* Consultation */
.vis-item.timeline-issued {
  color: white;
  background-color: #fd349c;
}

/* Final rules */
.vis-item.timeline-effective {
  background-color: #00b8f5;
}

.list-enter-active, .list-leave-active {
  transition: opacity 2s;
}
.list-enter, .list-leave-to, .fade-leave-active {
  opacity: 0;
}

</style>
